import merge from 'lodash/merge';
import { enUS as enUSAdapter, fr as frFRAdapter } from 'date-fns/locale';
// core
import { enUS as enUSCore, frFR as frFRCore } from '@mui/material/locale';
// date-pickers
import { enUS as enUSDate, frFR as frFRDate } from '@mui/x-date-pickers/locales';
// data-grid
import { enUS as enUSDataGrid, frFR as frFRDataGrid } from '@mui/x-data-grid';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'French',
    value: 'fr',
    systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
    adapterLocale: frFRAdapter,
    icon: 'flagpack:fr',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
];

export const defaultLang = allLangs[0]; // English
