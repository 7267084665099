import React, { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import { useLocales } from 'locales';
import Box from '@mui/material/Box';
import { useSnackbar } from 'components/snackbar';
import { UploadAvatar } from 'components/upload';
import { Typography } from '@mui/material';
import { fData } from 'utils/format-number';
import Button from '@mui/material/Button';
import Compressor from 'compressorjs';
import useAuth from 'hooks/useAuth';
import { UpdateRestaurantMediaTypes } from 'types/request';
import { RestaurantService } from 'api/services';
import { Restaurant } from 'types/response';

type Props = {
  setLoading: (state: boolean) => void;
  onSuccess: () => void;
};

export const SecondStep = forwardRef((props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const { setLoading, onSuccess } = props;
  const { t } = useLocales();
  const { user, accessToken, loginUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [avatarUrl, setAvatarUrl] = useState<File | null>(null);

  const handleDropAvatar = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setAvatarUrl(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);

  const handleSubmit = () => {
    if (!avatarUrl) {
      enqueueSnackbar(t('create_restaurant_avatar_required'), { variant: 'error' });
      return;
    }
    setLoading(true);

    (() =>
      new Compressor(avatarUrl, {
        quality: 0.8,
        success: async (result) => {
          const formData = new FormData();
          if (result instanceof File || result instanceof Blob) {
            formData.append('media', result, (result as any).name);
          }
          formData.append('restaurantUuid', user?.restaurant?.[0]?.uuid ?? '');
          formData.append('mediaType', UpdateRestaurantMediaTypes.LOGO);

          return RestaurantService.updateRestaurantMedia(formData)
            .then((storage) => {
              const newRestaurant = user?.restaurant?.[0] as Restaurant;
              newRestaurant.logo = storage;
              loginUser({
                accessToken,
                user: {
                  ...user!,
                  restaurant: [newRestaurant],
                },
              });
              enqueueSnackbar(t('create_restaurant_avatar_success'), { variant: 'success' });
              onSuccess();
            })
            .catch(() => {
              enqueueSnackbar(t('internal_server_error'), { variant: 'error' });
            })
            .finally(() => setLoading(false));
        },
        error: (err) => {
          setLoading(false);
          enqueueSnackbar(err.message, { variant: 'error' });
        },
      }))();
  };

  return (
    <Box>
      <UploadAvatar
        file={avatarUrl}
        onDrop={handleDropAvatar}
        helperText={
          <Typography
            variant="caption"
            sx={{
              mt: 3,
              mx: 'auto',
              display: 'block',
              textAlign: 'center',
              color: 'text.disabled',
            }}
          >
            {t('allowed_image_formats')}
            <br /> {t('file_max_size')} {fData(3145728)}
          </Typography>
        }
      />

      <Button ref={ref} onClick={handleSubmit} />
    </Box>
  );
});
