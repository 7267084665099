// @mui
import Box, { BoxProps } from '@mui/material/Box';
// hooks
import { useResponsive } from 'hooks/use-responsive';
// components
import { useSettingsContext } from 'components/settings';
//
import useAuth from 'hooks/useAuth';
import CreateRestaurantWidget from 'pages/home/CreateRestaurant';
import { useEffect } from 'react';
import { io } from 'socket.io-client';
import config from 'config-global';
import { Logger } from 'utils/log';
import { HEADER, NAV } from '../config-layout';

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const { user, accessToken } = useAuth();
  let createRestaurant = false;
  let initialStep = 0;

  const socket = io(config.socket_url ?? '', {
    auth: {
      token: accessToken?.replaceAll('"', ''),
    },
    autoConnect: true,
    transports: ['websocket'],
  });

  useEffect(() => {
    socket.on('connect', () => {
      Logger.log('socket connected');

      socket.emit('first_connection', { token: accessToken?.replaceAll('"', '') });

      socket.on('new_notification', (data: any) => {
        Logger.log(data);
      });
    });

    socket.on('disconnect', () => {
      Logger.log('socket disconnected');
    });

    socket.on('connect_error', (err) => {
      Logger.error('socket connect error', err.message);
    });

    return () => {
      socket.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uuid, socket]);

  if ((user?.restaurant ?? []).length === 0) {
    createRestaurant = true;
  } else if ((user?.restaurant ?? []).length > 0) {
    if (user?.restaurant?.[0]?.logo === null) {
      createRestaurant = true;
      initialStep = 1;
    } else if (user?.restaurant?.[0]?.banner === null) {
      createRestaurant = true;
      initialStep = 2;
    }
  }

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + 24}px`,
          pb: 10,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >
        {createRestaurant && <CreateRestaurantWidget initialStep={initialStep} />}
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {createRestaurant && <CreateRestaurantWidget initialStep={initialStep} />}
      {children}
    </Box>
  );
}
