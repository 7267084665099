/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
export abstract class Logger {
  static log(...args: any[]) {
    if (process.env.NODE_ENV === 'development') {
      console.log(...args);
    }
  }

  static info(...args: any[]) {
    if (process.env.NODE_ENV === 'development') {
      console.info(...args);
    }
  }

  static warn(...args: any[]) {
    if (process.env.NODE_ENV === 'development') {
      console.warn(...args);
    }
  }

  static error(...args: any[]) {
    if (process.env.NODE_ENV === 'development') {
      console.error(...args);
    }
  }
}
