import { CreateReviewCommentPayload, ReportReviewPayload } from 'types/request';
import { ReviewUrls } from 'api/urls';
import BaseService from 'api/services/BaseService';
import { handleApiResponse } from 'api/services/helper';
import { DefaultResponse, ReviewComment } from 'types/response';

export default class ReviewService {
  static reportReview = (payload: ReportReviewPayload) =>
    handleApiResponse<DefaultResponse>(BaseService.postRequest(ReviewUrls.reportReview, payload, true));

  static getComments = (reviewUuid: string) =>
    handleApiResponse<ReviewComment[]>(BaseService.getRequest(ReviewUrls.getComments(reviewUuid), true));

  static postComment = (reviewUuid: string, payload: CreateReviewCommentPayload) =>
    handleApiResponse<ReviewComment>(BaseService.postRequest(ReviewUrls.postComment(reviewUuid), payload, true));
}
