import useGetDefaultData from 'api/hooks/common/useGetDefaultData';
import useAuth from 'hooks/useAuth';
import React, { createContext, useMemo, useContext, ReactNode } from 'react';
import { DefaultDataResponse } from 'types/response';

interface ConfigurationContextProps {
  defaultData: DefaultDataResponse | undefined | null;
  refetchDefaultData: () => void;
  isDefaultDataLoading: boolean;
}

const initialProps: ConfigurationContextProps = {
  defaultData: null,
  refetchDefaultData: () => {},
  isDefaultDataLoading: true,
};

const ConfigurationContext = createContext(initialProps);

interface ConfigurationContextProviderProps {
  children: ReactNode;
}

function ConfigurationProvider({ children }: ConfigurationContextProviderProps) {
  const { user } = useAuth();
  const { data, refetch, isLoading } = useGetDefaultData({
    enabled: !!user,
  });

  const value = useMemo(
    () => ({
      defaultData: data || null,
      refetchDefaultData: refetch,
      isDefaultDataLoading: isLoading,
    }),
    [data, refetch, isLoading]
  );

  return <ConfigurationContext.Provider value={value}>{children}</ConfigurationContext.Provider>;
}

const useConfigurationContext = () => useContext(ConfigurationContext);

export { ConfigurationProvider, ConfigurationContext, useConfigurationContext };
