import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { paths } from 'routes/paths';
import { SplashScreen } from '../../components/loading-screen';
import AuthGuard from '../AuthGuard';
import DashboardLayout from '../../layouts/dashboard';

const HomePage = lazy(() => import('pages/home/app'));
const ProfilePage = lazy(() => import('pages/profile/main'));
const MealListPage = lazy(() => import('pages/meal/list'));
const MealCreatePage = lazy(() => import('pages/meal/new'));
const MealEditPage = lazy(() => import('pages/meal/edit'));
const MealDetailsPage = lazy(() => import('pages/meal/details'));

const MenuListPage = lazy(() => import('pages/menu/list'));
const MenuCreatePage = lazy(() => import('pages/menu/new'));
const MenuEditPage = lazy(() => import('pages/menu/edit'));
const MenuDetailPage = lazy(() => import('pages/menu/detail'));

export const dashboardRoutes: RouteObject[] = [
  {
    path: '/app',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.dashboard.home,
        element: <HomePage />,
      },
      {
        path: 'meal',
        children: [
          { element: <MealListPage />, index: true },
          { path: 'list', element: <MealListPage /> },
          { path: 'new', element: <MealCreatePage /> },
          { path: ':id/edit', element: <MealEditPage /> },
          { path: ':id', element: <MealDetailsPage /> },
        ],
      },
      {
        path: 'menu',
        children: [
          { element: <MenuListPage />, index: true },
          { path: 'new', element: <MenuCreatePage /> },
          { path: ':id', element: <MenuDetailPage /> },
          { path: ':id/edit', element: <MenuEditPage /> },
        ],
      },
      {
        path: paths.dashboard.profile,
        element: <ProfilePage />,
      },
      {
        path: '*',
        element: <Navigate to={paths.dashboard.home} replace />,
      },
    ],
  },
];
