// utils

const ROOTS = {
  DASHBOARD: '/app',
  AUTH: '/auth',
};

// ----------------------------------------------------------------------

export const paths = {
  // DASHBOARD
  dashboard: {
    home: ROOTS.DASHBOARD,
    profile: `${ROOTS.DASHBOARD}/profile`,
    meal: {
      root: `${ROOTS.DASHBOARD}/meal`,
      new: `${ROOTS.DASHBOARD}/meal/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/meal/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/meal/${id}/edit`,
    },
    menu: {
      root: `${ROOTS.DASHBOARD}/menu`,
      new: `${ROOTS.DASHBOARD}/menu/new`,
      details: (uuid: string) => `${ROOTS.DASHBOARD}/menu/${uuid}`,
      edit: (uuid: string) => `${ROOTS.DASHBOARD}/menu/${uuid}/edit`,
    },
  },
  auth: {
    login: `${ROOTS.AUTH}/login`,
  },
};
