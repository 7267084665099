import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import AuthLayout from '../../layouts/auth/AuthLayout';
import VerifyLayout from '../../layouts/auth/VerifyLayout';
import { SplashScreen } from '../../components/loading-screen';

const LoginPage = lazy(() => import('pages/auth/login'));
const ResetPasswordPage = lazy(() => import('pages/auth/forgot-password'));
const RegisterPage = lazy(() => import('pages/auth/register'));
const VerifyPage = lazy(() => import('pages/auth/register-verify'));
const NewPasswordPage = lazy(() => import('pages/auth/new-password'));
const VerifyEmailPage = lazy(() => import('pages/auth/verify-email'));

const VerifyEmail = lazy(() => import('pages/auth/email-verify'));

export const authRoutes: RouteObject[] = [
  {
    path: '/auth',
    element: (
      <AuthLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </AuthLayout>
    ),
    children: [
      {
        path: '/auth/login',
        element: <LoginPage />,
      },
      {
        path: '/auth/register',
        element: <RegisterPage />,
      },
      {
        path: '/auth/validate-code',
        element: <VerifyPage />,
      },
      {
        path: '/auth/verify-email',
        element: <VerifyEmailPage />,
      },
      {
        path: '/auth/reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: '/auth/new-password',
        element: <NewPasswordPage />,
      },
      {
        path: '*',
        element: <Navigate to="/errors/error-404" replace />,
      },
    ],
  },
  {
    path: '/verify-email/:token',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <VerifyLayout>
          <VerifyEmail />
        </VerifyLayout>
      </Suspense>
    ),
  },
  {
    path: '/cancel-change-email/:token',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <VerifyLayout>
          <VerifyEmail isCancellation />
        </VerifyLayout>
      </Suspense>
    ),
  },
];
