import React from 'react';
import { Navigate } from 'react-router-dom';
import SecureLS from 'secure-ls';
import { Logger } from 'utils/log';
import { localStorageGetItem } from 'utils/storage-available';

const ls = new SecureLS({ encodingType: 'aes' });

function AuthGuard(props: any) {
  localStorageGetItem('token');

  const { children } = props;
  let token: any;
  try {
    token = ls.get('token');
  } catch (error) {
    Logger.log(error);
  }
  if (!token) {
    return <Navigate to="/auth/login" />;
  }

  return children;
}

export default AuthGuard;
