import { useMemo } from 'react';
// locales
import { useLocales } from 'locales';
// components
import SvgColor from 'components/svg-color';

import { paths } from 'routes/paths';
// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  meal: icon('ic_meal'),
  menu: icon('ic_menu'),
  user: icon('ic_user'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  return useMemo(
    () => [
      {
        subheader: t('overview'),
        items: [
          {
            title: t('dashboard'),
            path: '/app/home',
            icon: ICONS.dashboard,
          },
          {
            title: t('meals.meals'),
            path: paths.dashboard.meal.root,
            icon: ICONS.meal,
          },
          {
            title: t('menus.menus'),
            path: paths.dashboard.menu.root,
            icon: ICONS.menu,
          },
        ],
      },
      {
        subheader: t('common.settings'),
        items: [
          // USER
          {
            title: t('common.my_account'),
            path: paths.dashboard.profile,
            icon: ICONS.user,
          },
        ],
      },
    ],
    [t]
  );
}
