import React, { useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useLocales } from 'locales';
import { FirstStep } from 'sections/create-restaurant/first-step';
import LoadingButton from '@mui/lab/LoadingButton';
import { ThirdStep } from 'sections/create-restaurant/third-step';
import { SecondStep } from 'sections/create-restaurant/second-step';

interface CreateRestaurantWidgetProps {
  initialStep?: number;
}

const CreateRestaurantWidget = (props: CreateRestaurantWidgetProps) => {
  const { initialStep = 0 } = props;
  const [open, setOpen] = useState(true);
  const { t } = useLocales();
  const [step, setStep] = useState(initialStep);
  const [loading, setLoading] = useState(false);

  const firstStepRef = useRef<HTMLButtonElement>(null);
  const secondStepRef = useRef<HTMLButtonElement>(null);
  const thirdStepRef = useRef<HTMLButtonElement>(null);

  return (
    <Dialog open={open} aria-labelledby="create-restaurant-dialog-title" maxWidth="md" onClose={() => {}} fullWidth>
      <DialogTitle id="create-restaurant-dialog-title">
        {t(
          step === 0
            ? 'create_restaurant_dialog_title'
            : step === 1
              ? 'add_restaurant_logo_dialog_title'
              : 'add_restaurant_banner_dialog_title'
        )}
      </DialogTitle>

      <DialogContent>
        {step === 0 && (
          <FirstStep
            ref={firstStepRef}
            setLoading={setLoading}
            onSuccess={() => {
              setStep(1);
            }}
          />
        )}
        {step === 1 && (
          <SecondStep
            ref={secondStepRef}
            setLoading={setLoading}
            onSuccess={() => {
              setStep(2);
            }}
          />
        )}
        {step === 2 && (
          <ThirdStep
            ref={thirdStepRef}
            setLoading={setLoading}
            onSuccess={() => {
              setOpen(false);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="inherit"
          size="large"
          variant="contained"
          loading={loading}
          onClick={() => {
            if (step === 0) {
              firstStepRef.current?.click();
            }
            if (step === 1) {
              secondStepRef.current?.click();
            }
            if (step === 2) {
              thirdStepRef.current?.click();
            }
          }}
        >
          {t(step === 0 ? 'label_create_restaurant' : 'global_label_next')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRestaurantWidget;
