import { CommonUrls } from 'api/urls';
import { handleApiResponse } from 'api/services/helper';
import { DefaultDataResponse, ReviewComment } from 'types/response';
import { CreateReviewCommentPayload } from 'types/request';
import BaseService from './BaseService';

class CommonService {
  static getDefaultData = () =>
    handleApiResponse<DefaultDataResponse>(BaseService.getRequest(CommonUrls.DEFAULT_DATA, true));

  static postReviewComment = (payload: CreateReviewCommentPayload) =>
    handleApiResponse<ReviewComment>(BaseService.postRequest(CommonUrls.DEFAULT_DATA, payload, true));
}

export default CommonService;
