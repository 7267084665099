import { handleApiResponse } from 'api/services/helper';
import { Address, Restaurant, Schedule, Storage } from 'types/response';
import BaseService from './BaseService';
import { ProfileUrls } from '../urls';
import {
  UpdateAddressPayload,
  UpdatePasswordPayload,
  UpdateRestaurantPayload,
  UpdateRestaurantSchedulePayload,
  UpdateUserPayload,
} from '../../types/request';

class ProfileService {
  static getProfile = () => handleApiResponse<Restaurant>(BaseService.getRequest(ProfileUrls.ME, true));

  static updateUser = (payload: UpdateUserPayload) => BaseService.putRequest(ProfileUrls.USER, payload, true);

  static updateSchedules = (payload: UpdateRestaurantSchedulePayload) =>
    handleApiResponse<Schedule[]>(BaseService.putRequest(ProfileUrls.UPDATE_SCHEDULES, payload, true));

  static updateMedia = (formData: FormData) =>
    handleApiResponse<Storage>(BaseService.putFileRequest(ProfileUrls.UPDATE_MEDIA, formData, true));

  static updateRestaurant = (payload: UpdateRestaurantPayload) => BaseService.putRequest(ProfileUrls.ME, payload, true);

  static updateAddress = (payload: UpdateAddressPayload) =>
    handleApiResponse<Address>(BaseService.putRequest(ProfileUrls.ADDRESS, payload, true));

  static changePassword = (payload: UpdatePasswordPayload) =>
    BaseService.putRequest(ProfileUrls.CHANGE_PASSWORD, payload, true);
}

export default ProfileService;
