import React, { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import { useLocales } from 'locales';
import Box from '@mui/material/Box';
import { useSnackbar } from 'components/snackbar';
import { Upload } from 'components/upload';
import Button from '@mui/material/Button';
import Compressor from 'compressorjs';
import useAuth from 'hooks/useAuth';
import { UpdateRestaurantMediaTypes } from 'types/request';
import { RestaurantService } from 'api/services';
import { Restaurant } from 'types/response';

type Props = {
  setLoading: (state: boolean) => void;
  onSuccess: () => void;
};

export const ThirdStep = forwardRef((props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const { setLoading, onSuccess } = props;
  const { t } = useLocales();
  const { user, accessToken, loginUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState<File | null>(null);

  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);

  const handleSubmit = () => {
    if (!file) {
      enqueueSnackbar(t('restaurant_banner_required'), { variant: 'error' });
      return;
    }
    setLoading(true);

    (() =>
      new Compressor(file, {
        quality: 0.8,
        success: async (result) => {
          const formData = new FormData();
          if (result instanceof File || result instanceof Blob) {
            formData.append('media', result, (result as any).name);
          }
          formData.append('restaurantUuid', user?.restaurant?.[0]?.uuid ?? '');
          formData.append('mediaType', UpdateRestaurantMediaTypes.BANNER);

          return RestaurantService.updateRestaurantMedia(formData)
            .then((storage) => {
              const newRestaurant = user?.restaurant?.[0] as Restaurant;
              newRestaurant.banner = storage;
              loginUser({
                accessToken,
                user: {
                  ...user!,
                  restaurant: [newRestaurant],
                },
              });
              enqueueSnackbar(t('restaurant_banner_success'), { variant: 'success' });
              onSuccess();
            })
            .catch((err) => {
              enqueueSnackbar(err.message ?? t('internal_server_error'), { variant: 'error' });
            })
            .finally(() => setLoading(false));
        },
        error: (err) => {
          setLoading(false);
          enqueueSnackbar(err.message, { variant: 'error' });
        },
      }))();
  };

  return (
    <Box>
      <Upload
        file={file}
        onDrop={handleDropSingleFile}
        onDelete={() => setFile(null)}
        accept={{
          'image/*': ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'],
        }}
      />

      <Button ref={ref} onClick={handleSubmit} />
    </Box>
  );
});
