import { Logger } from 'utils/log';

export async function handleApiResponse<T>(request: Promise<Response>): Promise<T> {
  const response = await request;
  const result = await response.json();
  if ([200, 201].includes(response.status)) {
    return result as T;
  }
  Logger.error(result);
  throw new Error(result.message);
}
