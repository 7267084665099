// @ts-ignore
const host_url = global.location.host;
let host_var = 'DEV';

if (host_url === process.env.REACT_APP_PROD_SERVER) {
  host_var = 'PROD';
} else if (host_url === process.env.REACT_APP_DEV_SERVER) {
  host_var = 'DEV';
}

const data = {
  env: host_var,
  api_url: process.env[`REACT_APP_${host_var}_API_URL`],
  socket_url: process.env[`REACT_APP_${host_var}_SOCKET_URL`],
};

export default data;

export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;
