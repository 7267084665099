import BaseService from './BaseService';
import { AuthUrls } from '../urls';
import { LoginPayload, RegisterPayload, ResetPasswordPayload } from '../../types/request';

class AuthService {
  static login = (info: LoginPayload) => BaseService.postRequest(AuthUrls.LOGIN, info, false);

  static register = (data: RegisterPayload) => BaseService.postRequest(AuthUrls.REGISTER, data, false);

  static validateCode = (code: string) => BaseService.postRequest(AuthUrls.VALIDATE_CODE, { code }, false);

  static resendCode = (email: string) => BaseService.putRequest(AuthUrls.RESEND_CODE, { email }, false);

  static initResetPassword = (email: string) => BaseService.putRequest(AuthUrls.INIT_RESET_PASSWORD, { email }, false);

  static resetPasswordResendCode = (email: string) =>
    BaseService.putRequest(AuthUrls.RESET_PASSWORD_RESEND_CODE, { email }, false);

  static resetPassword = (data: ResetPasswordPayload) => BaseService.putRequest(AuthUrls.RESET_PASSWORD, data, false);

  static verifyEmail = (token: string) =>
    BaseService.patchRequest(AuthUrls.VERIFY_EMAIL.replace(':token', token), {}, false);

  static cancelChangeEmail = (token: string) =>
    BaseService.patchRequest(AuthUrls.CANCEL_CHANGE_EMAIL.replace(':token', token), {}, false);

  static validateEmail = (token: string) => BaseService.postRequest(AuthUrls.VALIDATE_EMAIL(token), {}, false);
}

export default AuthService;
