import React from 'react';

// i18n
import 'locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
// routes
import Router from 'routes';
// theme
import ThemeProvider from 'theme';
// locales
import { LocalizationProvider } from 'locales';
// hooks
import { useScrollToTop } from 'hooks/use-scroll-to-top';
// components
import ProgressBar from 'components/progress-bar';
import { MotionLazy } from 'components/animate/motion-lazy';
import SnackbarProvider from 'components/snackbar/snackbar-provider';
import { SettingsDrawer, SettingsProvider } from 'components/settings';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from 'context/AuthContext';
import { DEFAULT_QUERY_RETRY_COUNT } from 'api/constants';
import { ConfigurationProvider } from 'context/ConfigurationContext';

export default function App() {
  useScrollToTop();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retryDelay: 10 * 1000,
        retry: process.env.MODE !== 'DEV' && DEFAULT_QUERY_RETRY_COUNT,
      },
      mutations: {
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LocalizationProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <ConfigurationProvider>
                <MotionLazy>
                  <SnackbarProvider>
                    <SettingsDrawer />
                    <ProgressBar />
                    <Router />
                  </SnackbarProvider>
                </MotionLazy>
              </ConfigurationProvider>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
