import BaseService from 'api/services/BaseService';
import { MenuUrls } from 'api/urls';
import { DefaultResponse, Menu, PaginatedData, ReviewListResponse } from 'types/response';
import { handleApiResponse } from 'api/services/helper';
import { PaginationQuery } from 'types/request';

class MenuService {
  static listMenus = (queryParams: Record<string, string>) =>
    handleApiResponse<PaginatedData<Menu>>(
      BaseService.getRequest(`${MenuUrls.LIST}?${new URLSearchParams(queryParams).toString()}`, true)
    );

  static getMenu = (uuid: string) => handleApiResponse<Menu>(BaseService.getRequest(MenuUrls.GET(uuid), true));

  static createMenu = (payload: FormData) =>
    handleApiResponse<Menu>(BaseService.postFileRequest(MenuUrls.CREATE, payload, true));

  static deleteMenu = (uuid: string) =>
    handleApiResponse<DefaultResponse>(BaseService.deleteRequest(MenuUrls.DELETE(uuid), {}, true));

  static updateMenu = (uuid: string, payload: FormData) =>
    handleApiResponse<Menu>(BaseService.putFileRequest(MenuUrls.UPDATE(uuid), payload, true));

  static getMenuReviews = (menuUuid: string, queryParams: PaginationQuery) =>
    handleApiResponse<ReviewListResponse>(
      BaseService.getRequest(
        `${MenuUrls.getMenuReviews(menuUuid)}?${new URLSearchParams(queryParams as unknown as Record<string, string>).toString()}`,
        false
      )
    );
}

export default MenuService;
