import { useQuery } from '@tanstack/react-query';
import { DEFAULT_DATA_QUERY_KEY } from 'api/constants';
import CommonService from 'api/services/CommonService';
import { UseGetDefaultDataProps } from './common.types';

export default function useGetDefaultData({ ...props }: UseGetDefaultDataProps) {
  return useQuery({
    queryKey: [DEFAULT_DATA_QUERY_KEY],
    queryFn: async () => {
      const defaultData = await CommonService.getDefaultData();
      return defaultData || null;
    },
    refetchInterval: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: true,
    ...props,
  });
}
