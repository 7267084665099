import SecureLS from 'secure-ls';
import { localStorageGetItem } from 'utils/storage-available';

const ls = new SecureLS({ encodingType: 'aes' });

class BaseService {
  static getHeaders = (isFile?: boolean) => {
    const headers = new Headers();
    if (!isFile) {
      headers.append('Content-Type', 'application/json');
    }
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Origin', '*');
    headers.append('Credentials', 'same-origin');

    const lang = localStorageGetItem('i18nextLng') ?? 'fr';
    // const lang = ls.get('i18nextLng') ?? 'fr';

    headers.append('Accept-Language', lang);
    return headers;
  };

  static getHeadersAuth = (isFile?: boolean) => {
    const headers = BaseService.getHeaders(isFile);
    let token: string = ls.get('token') ? (ls.get('token') || '').toString() : '';
    if (token === '') {
      window.location.reload();
    }
    token = token.replaceAll('"', '');
    headers.append('Authorization', `Bearer ${token}`);
    return headers;
  };

  static getToken = () => (ls.get('token') || '').toString();

  static postRequest = async (url: string, body: object, requiredAuth: boolean) => {
    const head = requiredAuth ? BaseService.getHeadersAuth() : BaseService.getHeaders();

    const headers: RequestInit = {
      method: 'POST',
      headers: head,
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(body),
    };

    const response = await fetch(url, headers)
      .then((res) => res)
      .catch((err) => err);
    return response;
  };

  static postFileRequest = async (url: string, body: FormData, requiredAuth: boolean) => {
    const head = requiredAuth ? BaseService.getHeadersAuth(true) : BaseService.getHeaders(true);

    const headers: RequestInit = {
      method: 'POST',
      headers: head,
      mode: 'cors',
      cache: 'default',
      body,
    };
    const response = await fetch(url, headers)
      .then((res) => res)
      .catch((err) => err);
    return response;
  };

  static putFileRequest = async (url: string, body: FormData, requiredAuth: boolean) => {
    const head = requiredAuth ? BaseService.getHeadersAuth(true) : BaseService.getHeaders(true);

    const headers: RequestInit = {
      method: 'PUT',
      headers: head,
      mode: 'cors',
      cache: 'default',
      body,
    };
    const response = await fetch(url, headers)
      .then((res) => res)
      .catch((err) => err);
    return response;
  };

  static putRequest = async (url: string, body: object, requiredAuth: boolean) => {
    const head = requiredAuth ? BaseService.getHeadersAuth() : BaseService.getHeaders();

    const headers: RequestInit = {
      method: 'PUT',
      headers: head,
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(body),
    };
    const response = await fetch(url, headers)
      .then((res) => res)
      .catch((err) => err);
    return response;
  };

  static patchRequest = async (url: string, body: object, requiredAuth: boolean) => {
    const head = requiredAuth ? BaseService.getHeadersAuth() : BaseService.getHeaders();

    const headers: RequestInit = {
      method: 'PATCH',
      headers: head,
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(body),
    };
    const response = await fetch(url, headers)
      .then((res) => res)
      .catch((err) => err);
    return response;
  };

  static deleteRequest = async (url: string, body: object, requiredAuth: boolean) => {
    const head = requiredAuth ? BaseService.getHeadersAuth() : BaseService.getHeaders();

    const headers: RequestInit = {
      method: 'DELETE',
      headers: head,
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(body),
    };
    const response = await fetch(url, headers)
      .then((res) => res)
      .catch((err) => err);
    return response;
  };

  static getRequest = async (url: string, requiredAuth: boolean) => {
    const head = requiredAuth ? BaseService.getHeadersAuth() : BaseService.getHeaders();

    const headers: RequestInit = {
      method: 'GET',
      headers: head,
      mode: 'cors',
      cache: 'default',
    };
    const response = await fetch(url, headers)
      .then((res) => res)
      .catch((err) => err);
    return response;
  };
}

export default BaseService;
