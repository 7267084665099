import React, { lazy } from 'react';
import { useRoutes } from 'react-router';
import { Navigate, RouteObject } from 'react-router-dom';
import { authRoutes } from './sections/auth';
import { dashboardRoutes } from './sections/dashboard';

const NotFoundPage = lazy(() => import('pages/404'));

const routesConfig: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/app/home" replace />,
  },
  {
    path: '/errors/error-404',
    element: <NotFoundPage />,
  },
  ...authRoutes,
  ...dashboardRoutes,
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export default function Router() {
  return useRoutes(routesConfig);
}
