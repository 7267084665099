// COUNT CONSTANTS
export const DEFAULT_QUERY_RETRY_COUNT = 5;
export const DEFAULT_MAX_PAGINATION_COUNT = 200;

// QUERY KEYS FOR REACT QUERY
export const MEALS_QUERY_KEY = 'meals';
export const MEALS_CATEGORIES_QUERY_KEY = 'mealCategories';
export const MEALS_SIZE_TYPE_QUERY_KEY = 'mealSizeType';
export const REVIEW_COMMENTS_QUERY_KEY = 'ReviewComments';
export const DEFAULT_DATA_QUERY_KEY = 'defaultData';

// MUTATION KEYS FOR REACT QUERY
export const POST_MEAL_MUTATION_KEY = 'postMeal';
export const PUT_MEAL_MUTATION_KEY = 'putMeal';
export const DELETE_MEAL_MUTATION_KEY = 'deleteMeal';
export const DELETE_MEAL_SIZE_MUTATION_KEY = 'deleteMealSize';
export const DELETE_MEALS_MUTATION_KEY = 'deleteMeals';

export const POST_MENU_MUTATION_KEY = 'new_menu';
export const PUT_MENU_MUTATION_KEY = 'update_menu';
export const DELETE_MENU_MUTATION_KEY = 'delete_menu';
export const DELETE_MENUS_MUTATION_KEY = 'delete_menus';
export const MENUS_QUERY_KEY = 'menus';
export const DEFAULT_MENUS_QUERY_KEY = [MENUS_QUERY_KEY, '0', DEFAULT_MAX_PAGINATION_COUNT];
export const POST_REVIEW_COMMENT_MUTATION_KEY = 'postReviewComment';

// TIME CONSTANTS
export const DATA_REFRESH_INTERVAL = 1000 * 60 * 5; // 5 minutes
export const COMMENT_REFRESH_INTERVAL = 1000 * 5; // 5 seconds
export const DATA_STALE_TIME = 1000 * 60 * 60 * 24; // 24 hours

export const DEFAULT_MEALS_QUERY_KEY = [MEALS_QUERY_KEY, '0', DEFAULT_MAX_PAGINATION_COUNT];
