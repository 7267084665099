import React, { ForwardedRef, forwardRef } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from 'locales';
import { verifyPhoneNumber } from 'utils/validators';
import FormProvider, { RHFTextField } from 'components/hook-form';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import RhfAddressPicker from 'components/hook-form/rhf-address-picker';
import { Button } from '@mui/material';
import { CreateRestaurantPayload } from 'types/request';
import { RestaurantService } from 'api/services';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'components/snackbar';

type Props = {
  setLoading: (state: boolean) => void;
  onSuccess: () => void;
};

export const FirstStep = forwardRef((props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const { setLoading, onSuccess } = props;
  const { t } = useLocales();

  const { loginUser, user, accessToken } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const FormSchema = Yup.object().shape({
    name: Yup.string().required(t('field_required')),
    description: Yup.string().required(t('field_required')),
    phoneNumber: Yup.string()
      .required(t('field_required'))
      .test('is-phone', t('incorrect_phone_number'), (value) => verifyPhoneNumber(value)),
    address: Yup.object()
      .shape({
        lat: Yup.number().required(t('field_required')),
        lng: Yup.number().required(t('field_required')),
      })
      .test('address', t('field_required'), (value) => value.lat > 0 && value.lng > 0)
      .required(t('field_required')),
    addressSupplement: Yup.string().required(t('field_required')),
  });

  const defaultValues = {
    name: '',
    description: '',
    phoneNumber: '',
    address: {
      lat: 0,
      lng: 0,
    },
    addressSupplement: '',
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const payload: CreateRestaurantPayload = {
      name: data.name,
      description: data.description,
      phoneNumber: data.phoneNumber,
      address: {
        addressSupplement: data.addressSupplement,
        lat: data.address?.lat,
        lng: data.address.lng,
        title: data.name,
        district: '',
        town: '',
      },
    };
    return RestaurantService.createRestaurant(payload)
      .then((newRestaurant) => {
        loginUser({
          user: {
            ...user!,
            restaurant: [newRestaurant],
          },
          accessToken,
        });
        enqueueSnackbar(t('restaurant_create_success'), { variant: 'success' });
        onSuccess();
      })
      .catch((err) => {
        enqueueSnackbar(err.message ?? t('internal_server_error'), { variant: 'error' });
      })
      .finally(() => setLoading(false));
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Box mt={2} />
      <Stack spacing={2}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="name" label={t('field_restaurant_name')} />
          <RHFTextField type="number" name="phoneNumber" label={t('field_phone_number')} />
        </Stack>
        <RHFTextField name="description" label={t('field_restaurant_description')} multiline rows={4} />
        <RhfAddressPicker name="address" label={t('field_restaurant_address')} />
        <RHFTextField
          name="addressSupplement"
          label={t('field_address_supplement')}
          helperText={t('address_supplement_help_text')}
        />
      </Stack>
      <Button component="button" type="submit" sx={{ display: 'none' }} ref={ref} />
    </FormProvider>
  );
});
