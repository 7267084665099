import config from 'config-global';

export const prefix = config.api_url;

export const AuthUrls = {
  LOGIN: `${prefix}auth/merchant/login`,
  REGISTER: `${prefix}auth/merchant/register`,
  VALIDATE_CODE: `${prefix}auth/validate-code`,
  RESEND_CODE: `${prefix}auth/resend-code`,
  INIT_RESET_PASSWORD: `${prefix}auth/init-password-recovery`,
  RESET_PASSWORD_RESEND_CODE: `${prefix}auth/password-recovery/resend-code`,
  RESET_PASSWORD: `${prefix}auth/password-recovery/validation`,
  VALIDATE_EMAIL: (token: string) => `${prefix}auth/merchant/verify?token=${token}`,
  VERIFY_EMAIL: `${prefix}merchants/verify-email/:token`,
  CANCEL_CHANGE_EMAIL: `${prefix}merchants/cancel-change-email/:token`,
} as const;

export const ProfileUrls = {
  ME: `${prefix}merchants/profile/me`,
  USER: `${prefix}merchants/profile/me/user`,
  ADDRESS: `${prefix}merchants/profile/me/address`,
  CHANGE_PASSWORD: `${prefix}merchants/profile/me/user/change-password`,
  UPDATE_SCHEDULES: `${prefix}merchants/update-restaurant-schedule`,
  UPDATE_MEDIA: `${prefix}merchants/update-restaurant-media`,
} as const;

export const RestaurantUrls = {
  CREATE: `${prefix}merchants/create-restaurant`,
  UPDATE_MEDIA: `${prefix}merchants/update-restaurant-media`,
} as const;

export const MealUrls = {
  LIST: `${prefix}merchants/meals`,
  LIST_MEAL_TYPES: `${prefix}merchants/meal-types`,
  LIST_MEAL_CATEGORIES: `${prefix}merchants/meals/categories`,
  LIST_MEAL_SIZE_TYPE: `${prefix}merchants/meals/size-types`,
  GET: (uuid: string) => `${prefix}merchants/meals/${uuid}`,
  CREATE: `${prefix}merchants/meals`,
  UPDATE: (uuid: string) => `${prefix}merchants/meals/${uuid}`,
  DELETE: (uuid: string) => `${prefix}merchants/meals/${uuid}`,
  DELETE_MEAL_SIZE: `${prefix}merchants/meals/meal-sizes`,
  getMealReviews: (uuid: string) => `${prefix}merchants/review/meal/${uuid}`,
} as const;

export const MenuUrls = {
  LIST: `${prefix}merchants/menus`,
  GET: (uuid: string) => `${prefix}merchants/menus/${uuid}`,
  CREATE: `${prefix}merchants/menus`,
  UPDATE: (uuid: string) => `${prefix}merchants/menus/${uuid}`,
  DELETE: (uuid: string) => `${prefix}merchants/menus/${uuid}`,
  getMenuReviews: (uuid: string) => `${prefix}merchants/review/menu/${uuid}`,
} as const;

export const ReviewUrls = {
  reportReview: `${prefix}merchants/report-review`,
  postComment: (uuid: string) => `${prefix}merchants/review/${uuid}/comment`,
  getComments: (uuid: string) => `${prefix}merchants/review/${uuid}/comment`,
} as const;

export const CommonUrls = {
  DEFAULT_DATA: `${prefix}merchants/default-data`,
} as const;
