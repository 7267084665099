import BaseService from 'api/services/BaseService';
import { MealUrls } from 'api/urls';
import { Category, DefaultResponse, Meal, SizeType, PaginatedData, ReviewListResponse } from 'types/response';
import { PaginationQuery } from 'types/request';
import { handleApiResponse } from './helper';

class MealService {
  static listMeals = async (queryParams: Record<string, string>) =>
    handleApiResponse<PaginatedData<Meal>>(
      BaseService.getRequest(`${MealUrls.LIST}?${new URLSearchParams(queryParams).toString()}`, true)
    );

  static getMeal = async (uuid: string) => handleApiResponse<Meal>(BaseService.getRequest(MealUrls.GET(uuid), true));

  static listMealTypes = async () => handleApiResponse(BaseService.getRequest(MealUrls.LIST_MEAL_TYPES, true));

  static listMealCategories = async () =>
    handleApiResponse<Category[]>(BaseService.getRequest(MealUrls.LIST_MEAL_CATEGORIES, true));

  static listMealSizeType = async () =>
    handleApiResponse<SizeType[]>(BaseService.getRequest(MealUrls.LIST_MEAL_SIZE_TYPE, true));

  static postMeal = async (payload: FormData) =>
    handleApiResponse<Meal>(BaseService.postFileRequest(MealUrls.CREATE, payload, true));

  static putMeal = async (uuid: string, payload: FormData) =>
    handleApiResponse<Meal>(BaseService.putFileRequest(MealUrls.UPDATE(uuid), payload, true));

  static deleteMeal = async (uuid: string) =>
    handleApiResponse<DefaultResponse>(BaseService.deleteRequest(MealUrls.DELETE(uuid), {}, true));

  static deleteMealSize = async (mealUuid: string, sizeUuid: string) =>
    handleApiResponse<DefaultResponse>(
      BaseService.deleteRequest(MealUrls.DELETE_MEAL_SIZE, { mealUuid, sizeUuid }, true)
    );

  static getMealReviews = (mealUuid: string, queryParams: PaginationQuery) =>
    handleApiResponse<ReviewListResponse>(
      BaseService.getRequest(
        `${MealUrls.getMealReviews(mealUuid)}?${new URLSearchParams(
          queryParams as unknown as Record<string, string>
        ).toString()}`,
        false
      )
    );
}

export default MealService;
