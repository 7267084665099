import { EReportType } from 'types/enums';

export interface LoginPayload {
  email: string;
  password: string;
}

export interface UpdateAddressPayload {
  title: string;
  town: string;
  district: string;
  addressSupplement: string;
  lat: number;
  lng: number;
}

export interface UpdateUserPayload {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export interface UpdateRestaurantPayload {
  name: string;
  description: string;
  phoneNumber: string;
  address: {
    title: string;
    town: string;
    district: string;
    addressSupplement: string;
    lat: number;
    lng: number;
  };
}

export interface UpdateRestaurantMediaPayload {
  restaurantUuid: string;
  media: File;
  mediaType: 'logo' | 'banner';
}

export interface UpdatePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

export interface ScheduleDto {
  day: string;
  openTime: string;
  closeTime: string;
}

export interface UpdateRestaurantSchedulePayload {
  restaurantUuid: string;
  schedule: ScheduleDto[];
}

export interface RegisterPayload {
  firstName?: string;
  lastName?: string;
  password?: string;
  email?: string;
  phoneNumber?: string;
}

export interface ResetPasswordPayload {
  token: string;
  email: string;
  new_password: string;
}

export interface CreateRestaurantPayload {
  name?: string;
  description?: string;
  phoneNumber?: string;
  address?: CreateAddressPayload;
}

export interface CreateAddressPayload {
  title?: string;
  town?: string;
  district?: string;
  addressSupplement?: string;
  lat?: number;
  lng?: number;
}

export enum UpdateRestaurantMediaTypes {
  LOGO = 'logo',
  BANNER = 'banner',
}

export interface CreateOrUpdateMealSize {
  uuid?: string;
  sizeTypeUuid: string;
  price: number;
  promotionalPrice?: number;
  inPromotion: boolean;
  available: boolean;
}

export interface CreateMealDto {
  name: string;
  description: string;
  mealType: string;
  category: string;
  bakingTime: number;
  image: File;
  gallery?: File[];
  group: string;
  sizes: string; // JSON stringified array of CreateOrUpdateMealSize[]
}

export interface CreateMenuPayload {
  name: string;
  description: string;
  mealsUuid: string[];
  price: number;
  inPromotion: boolean;
  promotionalPrice?: number;
  image: File;
  available: boolean;
  bakingTime: number;
}

export type UpdateMenuPayload = CreateMenuPayload & {
  imageUuid?: string;
};

export interface UpdateMealDto {
  name: string;
  description: string;
  mealType: string;
  category: string;
  bakingTime: number;
  image: File;
  imageUuid: string;
  gallery?: File[];
  galleryUuid?: string[];
  group: string;
  sizes: string; // JSON stringified array of CreateOrUpdateMealSize[]
}

export type PaginationQuery = {
  take: number;
  skip: number;
  dir?: 'asc' | 'desc';
};

export type ReportReviewPayload = {
  reviewUuid: string;
  comment?: string;
  type: EReportType;
};

export interface CreateReviewCommentPayload {
  content: string;
}
