import { CreateRestaurantPayload } from 'types/request';
import BaseService from 'api/services/BaseService';
import { RestaurantUrls } from 'api/urls';
import { handleApiResponse } from 'api/services/helper';
import { Restaurant, Storage } from 'types/response';

class RestaurantService {
  static createRestaurant = (data: CreateRestaurantPayload) =>
    handleApiResponse<Restaurant>(BaseService.postRequest(RestaurantUrls.CREATE, data, true));

  static updateRestaurantMedia = (data: FormData) =>
    handleApiResponse<Storage>(BaseService.putFileRequest(RestaurantUrls.UPDATE_MEDIA, data, true));
}

export default RestaurantService;
