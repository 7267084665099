import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
        '.cls-1': { fill: '#ffd460', strokeWidth: 0 },
      }}
      {...other}
    >
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
        <path
          className="cls-1"
          d="m422,211.13c0,17.07-2.5,33.54-7.13,49.07-2.29,7.77-5.11,15.34-8.45,22.58-3.78,8.29-8.21,16.18-13.2,23.67-2.05,3.02-4.15,6-6.4,8.9-.12.16-.24.28-.32.44-4.55,5.96-9.5,11.59-14.82,16.91l-.04.04-60.19,73.39-.16.2-29.31,35.75-8.94,10.87c-6.04,4.95-13.81,7.89-22.14,7.89-8.94,0-16.95-3.34-23.11-8.74-1.45-1.21-2.78-2.54-3.99-3.99l-.08-.12-24.4-29.75v-.04h-.04l-25.89-31.6s0-.04-.04-.04l-15.9-19.32c-.52-3.86-.85-7.77-1.05-11.76-.08-1.97-.12-3.99-.12-6,0-67.07,49.24-122.63,113.53-132.45,3.54.68,6.64,2.62,8.9,5.27,1.21,1.49,2.21,3.18,2.82,5.07,6.12,9.1,16.51,15.14,28.22,15.18h38.69c1.89-.04,3.42-1.61,3.38-3.46-.04-1.89-1.57-3.38-3.46-3.38h-38.61v-12.96h39.21c1.77-.44,3.06-2.05,3.06-3.95-.04-2.05-1.61-3.7-3.58-3.91h-38.69l.04-12.96h38.25c2.21-.04,3.99-1.85,3.99-4.07,0-.6-.16-1.21-.44-1.73-.08-.16-.2-.36-.32-.52-.04-.12-.16-.24-.24-.32-.52-.6-1.25-1.05-2.05-1.25h-39.17v-11.84h39.13c1.69-.48,2.94-2.05,2.9-3.9-.04-2.17-1.81-3.95-3.99-3.95h-38.45c-11.31.16-21.3,5.84-27.38,14.45-.56,1.21-1.25,2.34-2.09,3.38-.04.04-.08.08-.12.16-1.93,2.25-4.43,3.99-7.33,4.87h-.04c-1.85.2-3.7.44-5.56.72h-.12c-19.77,3.06-38.29,9.86-54.79,19.69-42.67,25.4-71.94,70.85-74.8,123.35-1.61-1.41-3.18-2.86-4.75-4.31l-19.12-23.27s0-.04-.04-.08c-4.55-13.45-7.01-27.86-7.01-42.87,0-73.95,59.98-133.94,133.94-133.94,1.61,0,3.22.04,4.79.08,3.54.12,7.09.4,10.55.8,2.17.97,4.11,2.42,5.64,4.23.8.93,1.49,1.97,2.05,3.1,6.28,8.53,16.47,14.05,27.9,13.89,5.8-.08,11.23-1.61,15.94-4.23,6.36-3.5,11.47-8.98,14.53-15.58,2.09-4.47,3.22-9.5,3.14-14.77-.28-18.84-15.74-33.9-34.58-33.62-11.92.16-22.34,6.4-28.3,15.78v.04c-.64.97-1.21,1.97-1.73,3.02-2.29,3.26-5.8,5.6-9.9,6.36-2.38-.2-4.75-.28-7.17-.32-.93-.04-1.89-.04-2.86-.04-5.39,0-10.75.28-16.02.85-21.22,2.21-41.14,8.82-58.9,18.8,0,0-.04,0-.04.04-45.53,25.77-76.73,74.28-78.26,130.27v.93c-5.8-17.27-8.98-35.75-8.98-55.03,0-94.97,77.01-171.98,172.02-171.98s171.98,77.01,171.98,171.98Z"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

export default Logo;
