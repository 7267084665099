import { Controller, useFormContext } from 'react-hook-form';
// @mui
import Map from 'react-map-gl';
import { MapControl, MapMarker } from 'components/map';
import { styled } from '@mui/material/styles';
import { MAPBOX_API } from 'config-global';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useLocales } from 'locales';
import { useState } from 'react';
import { useSettingsContext } from 'components/settings';

// ----------------------------------------------------------------------

const StyledMapContainer = styled('div')(({ theme }) => ({
  zIndex: 0,
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
    display: 'none',
  },
}));

const THEMES = {
  light: 'mapbox://styles/mapbox/light-v10',
  dark: 'mapbox://styles/mapbox/dark-v10',
};

export interface LatLng {
  lat: number;
  lng: number;
}

type Props = {
  name: string;
  height?: number;
  label?: string;
};
export default function RhfAddressPicker(props: Props) {
  const { themeMode } = useSettingsContext();
  const { name, label, height, ...other } = props;
  const { control } = useFormContext();
  const { t } = useLocales();
  const [value, setValue] = useState<LatLng | null>(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack>
          <Typography
            variant="body2"
            sx={{
              my: 0.5,
            }}
          >
            {label}
          </Typography>
          <StyledMapContainer
            sx={{
              height: height || 420,
              border: (theme) => `1px solid ${error ? theme.palette.error.main : theme.palette.divider}`,
            }}
          >
            <Map
              initialViewState={{
                zoom: 11,
                latitude: 3.8667,
                longitude: 11.5167,
              }}
              mapStyle={THEMES[themeMode]}
              mapboxAccessToken={MAPBOX_API}
              {...other}
              onClick={(e) => {
                setValue({
                  lat: e.lngLat.lat,
                  lng: e.lngLat.lng,
                });
                field.onChange({
                  lat: e.lngLat.lat,
                  lng: e.lngLat.lng,
                });
              }}
            >
              <MapControl />
              {value && <MapMarker latitude={value.lat} longitude={value.lng} />}
            </Map>
          </StyledMapContainer>
          <Typography variant="caption" color={error ? 'error.main' : 'text.secondary'}>
            {error ? error?.message : t('select_address_help_text')}
          </Typography>
        </Stack>
      )}
    />
  );
}
