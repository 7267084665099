import React, { createContext, useMemo, useState } from 'react';
import { User } from 'types/response';
import SecureLS from 'secure-ls';
import { removeStorage, setStorage } from 'hooks/use-local-storage';

const ls = new SecureLS({ encodingType: 'aes' });

export interface AuthLogin {
  accessToken: string | undefined | null;
  user: User | undefined | null;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  accessToken: string | null | undefined;
  user: User | undefined | null;
  loginUser: (authLogin: AuthLogin) => void;
  logout: () => void;
}

const initialProps: AuthContextProps = {
  isAuthenticated: false,
  accessToken: null,
  user: null,
  loginUser: (data: AuthLogin) => {},
  logout: () => {},
};

const AuthContext = createContext(initialProps);

interface AuthContextProviderProps {
  children: React.ReactNode;
}

function AuthProvider({ children }: AuthContextProviderProps) {
  const user = useMemo(() => {
    try {
      return ls.get('user') ? JSON.parse(ls.get('user')) : undefined;
    } catch (error) {
      return undefined;
    }
  }, []);

  const accessToken = useMemo(() => {
    try {
      return ls.get('token') ? ls.get('token') : undefined;
    } catch (error) {
      return undefined;
    }
  }, []);

  const [state, setState] = useState({
    isAuthenticated: accessToken,
    accessToken,
    user,
  });

  // Mode

  const loginUser = (data: AuthLogin) => {
    setStorage('user', data.user);
    setStorage('token', data.accessToken);
    setState({
      isAuthenticated: !!data.accessToken,
      ...data,
    });
  };

  const logout = () => {
    setState({
      isAuthenticated: false,
      accessToken: null,
      user: null,
    });

    removeStorage('user');
    removeStorage('token');
  };

  const values = useMemo(
    () => ({
      accessToken: state.accessToken,
      user: state.user,
      isAuthenticated: !!accessToken,
      loginUser,
      logout,
    }),
    [state.accessToken, state.user, accessToken]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export { AuthProvider, AuthContext };
